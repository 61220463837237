@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  font-family: Montserrat, sans-serif;
  box-sizing: border-box;
}

.default-pill-bg-light {
  background: rgb(237, 237, 237);
}
.default-pill-text {
  color: darkslategray;
}
.success-text,
.completed-text,
.complete-text {
  color: #007b5d;
}
.warning-text {
  color: #f56b00;
}

.danger-text {
  color: #cf0921;
}

.info-text {
  color: rgb(141, 153, 153);
}

.dark-text {
  color: #424242;
}

.success-bg,
.completed-bg,
.complete-bg,
.complete {
  background: #007b5d;
}
.warning-bg,
.pending-bg,
.pending_payment-bg {
  background: #fcd20f;
}

.danger-bg,
.cancelled-bg {
  background: #cf0921;
}

.warning-bg-light {
  background: #fff2df;
}
.danger-bg-light {
  background: #fae5e8 !important;
}

.success-bg-light {
  background: #e5f1ee !important;
}

.info-bg-light {
  background: rgba(141, 141, 141, 0.2);
}

.pill {
  display: inline-block;
  padding: 4px 10px;
  border-radius: 19px;
  font: normal normal normal 15px/19px Montserrat;
}

.dark-bg {
  background: #424242;
}

.hide {
  display: none !important;
}

a {
  text-decoration: none;
  color: inherit;
}

.capitalize {
  text-transform: capitalize !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.lowercase {
  text-transform: lowercase !important;
}

.sentence-case {
  text-transform: lowercase !important;
}
.sentence-case::first-letter {
  text-transform: uppercase !important;
}
.selected-border {
  border: 1px solid #007b5d !important;
}

.selected-border-green {
  border: 1px solid #007b5d !important;
}

.selected-border-yellow {
  border: 1px solid #fcd20f !important;
}

.selected-border-red {
  border: 1px solid #cf0921 !important;
}

.invisible-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 2;
  background: rgba(0, 0, 0, 0);
}

.bold {
  font-weight: bold;
}

.payment-completed-bg-light {
  background: #cbdfd6;
}

.is-container {
  width: 100%;
  height: 100%;
  display: inline-block;
}

.btn-green-outline {
  box-shadow: 0px 2px 3px #0000000d;
  border: 1px solid #007b5d;
  border-radius: 5px;
  opacity: 1;
  font-size: 13px;
  letter-spacing: 0px;
  color: #007b5d;
  background-color: white;
  padding: 5px 10px;
  margin: 5px;
  transition: all 0.3s;
  cursor: pointer;
}
.btn-green-outline:hover {
  background-color: #007b5d;
  color: white;
}
.btn-red-outline {
  box-shadow: 0px 2px 3px #0000000d;
  border: 1px solid #cf0921;
  border-radius: 5px;
  opacity: 1;
  font-size: 13px;
  letter-spacing: 0px;
  color: #cf0921;
  background-color: white;
  padding: 5px 10px;
  margin: 5px;
  cursor: pointer;
}
.btn-red-outline:hover {
  background-color: #cf0921;
  color: white;
}

.grid-col-1-1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.grid-col-1-2 {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 10px;
}

.float-right {
  float: right;
}

.text-align-right {
  text-align: right;
}

.m-5 {
  margin: 5px;
}
.mt-5 {
  margin-top: 5px;
}

.wrapper {
  width: fit-content;
  height: fit-content;
}

.no-wrap {
  white-space: nowrap;
}

.cursor-pointer {
  cursor: pointer;
}

.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.d-inline {
  display: inline;
}

/** mt */
.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-85 {
  margin-top: 85px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-95 {
  margin-top: 95px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-125 {
  margin-top: 125px;
}

.mt-130 {
  margin-top: 125px;
}

.mt-150 {
  margin-top: 150px;
}

.mt-175 {
  margin-top: 175px;
}

.mt-180 {
  margin-top: 180px;
}

.mt-200 {
  margin-top: 200px;
}

/** mb */
.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-85 {
  margin-bottom: 85px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-95 {
  margin-bottom: 95px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-125 {
  margin-bottom: 125px;
}

.mb-150 {
  margin-bottom: 150px;
}

.mb-175 {
  margin-bottom: 175px;
}

.mb-200 {
  margin-bottom: 200px;
}

/** my */
.my-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.my-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.my-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.my-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.my-25 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.my-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.my-35 {
  margin-top: 35px;
  margin-bottom: 35px;
}

.my-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.my-45 {
  margin-top: 45px;
  margin-bottom: 45px;
}

.my-50 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.my-55 {
  margin-top: 55px;
  margin-bottom: 55px;
}

.my-60 {
  margin-top: 60px;
  margin-bottom: 60px;
}

.my-65 {
  margin-top: 65px;
  margin-bottom: 65px;
}

.my-70 {
  margin-top: 70px;
  margin-bottom: 70px;
}

.my-75 {
  margin-top: 75px;
  margin-bottom: 75px;
}

.my-80 {
  margin-top: 80px;
  margin-bottom: 80px;
}

.my-85 {
  margin-top: 85px;
  margin-bottom: 85px;
}

.my-90 {
  margin-top: 90px;
  margin-bottom: 90px;
}

.my-95 {
  margin-top: 95px;
  margin-bottom: 95px;
}

.my-100 {
  margin-top: 100px;
  margin-bottom: 100px;
}

.my-125 {
  margin-top: 125px;
  margin-bottom: 125px;
}

.my-150 {
  margin-top: 150px;
  margin-bottom: 150px;
}

.my-175 {
  margin-top: 175px;
  margin-bottom: 175px;
}

.my-200 {
  margin-top: 200px;
  margin-bottom: 200px;
}

/** ml */
.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-35 {
  margin-left: 35px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-45 {
  margin-left: 45px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-55 {
  margin-left: 55px;
}

.ml-60 {
  margin-left: 60px;
}

.ml-65 {
  margin-left: 65px;
}

.ml-70 {
  margin-left: 70px;
}

.ml-75 {
  margin-left: 75px;
}

.ml-80 {
  margin-left: 80px;
}

.ml-85 {
  margin-left: 85px;
}

.ml-90 {
  margin-left: 90px;
}

.ml-95 {
  margin-left: 95px;
}

.ml-100 {
  margin-left: 100px;
}

.ml-125 {
  margin-left: 125px;
}

.ml-150 {
  margin-left: 150px;
}

.ml-175 {
  margin-left: 175px;
}

.ml-200 {
  margin-left: 200px;
}

/** mr */
.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-35 {
  margin-right: 35px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-45 {
  margin-right: 45px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-55 {
  margin-right: 55px;
}

.mr-60 {
  margin-right: 60px;
}

.mr-65 {
  margin-right: 65px;
}

.mr-70 {
  margin-right: 70px;
}

.mr-75 {
  margin-right: 75px;
}

.mr-80 {
  margin-right: 80px;
}

.mr-85 {
  margin-right: 85px;
}

.mr-90 {
  margin-right: 90px;
}

.mr-95 {
  margin-right: 95px;
}

.mr-100 {
  margin-right: 100px;
}

.mr-125 {
  margin-right: 125px;
}

.mr-150 {
  margin-right: 150px;
}

.mr-175 {
  margin-right: 175px;
}

.mr-200 {
  margin-right: 200px;
}

/** mx */
.mx-5 {
  margin-left: 5px;
  margin-right: 5px;
}

.mx-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.mx-15 {
  margin-left: 15px;
  margin-right: 15px;
}

.mx-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.mx-25 {
  margin-left: 25px;
  margin-right: 25px;
}

.mx-30 {
  margin-left: 30px;
  margin-right: 30px;
}

.mx-35 {
  margin-left: 35px;
  margin-right: 35px;
}

.mx-40 {
  margin-left: 40px;
  margin-right: 40px;
}

.mx-45 {
  margin-left: 45px;
  margin-right: 45px;
}

.mx-50 {
  margin-left: 50px;
  margin-right: 50px;
}

.mx-55 {
  margin-left: 55px;
  margin-right: 55px;
}

.mx-60 {
  margin-left: 60px;
  margin-right: 60px;
}

.mx-65 {
  margin-left: 65px;
  margin-right: 65px;
}

.mx-70 {
  margin-left: 70px;
  margin-right: 70px;
}

.mx-75 {
  margin-left: 75px;
  margin-right: 75px;
}

.mx-80 {
  margin-left: 80px;
  margin-right: 80px;
}

.mx-85 {
  margin-left: 85px;
  margin-right: 85px;
}

.mx-90 {
  margin-left: 90px;
  margin-right: 90px;
}

.mx-95 {
  margin-left: 95px;
  margin-right: 95px;
}

.mx-100 {
  margin-left: 100px;
  margin-right: 100px;
}

.mx-125 {
  margin-left: 125px;
  margin-right: 125px;
}

.mx-150 {
  margin-left: 150px;
  margin-right: 150px;
}

.mx-175 {
  margin-left: 175px;
  margin-right: 175px;
}

.mx-200 {
  margin-left: 200px;
  margin-right: 200px;
}

.font-14 {
  font-size: 14px;
}

.medium {
  font-weight: 500;
}

.bold {
  font-weight: bold;
}

.bolder {
  font-weight: bolder;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.pointer,
.is-link {
  cursor: pointer;
}

.p-10 {
  padding: 10px;
}

.p-20 {
  padding: 20px;
}

.bold-600 {
  font-weight: 600;
}

.text-muted {
  color: #707070;
}

.text-green {
  color: #008161;
}
